@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");
* {
  margin: 0;
  padding: 0%;
  box-sizing: border-box;
  color: white;
}
body {
  /* width: 100vw;
  min-height: 100vh; */
  width: 100vw;
  
  font-family: "Poppins", sans-serif;
  
  min-height: 100vh;
  /* border: 2px solid red;
  */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #222;
}
.container {
  width: 400px;
  height: 98vh;
  /* border: 2px solid red; */
  box-shadow: 0px 0px 5px black;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.screen {
  width: 100%;
  height: 100%;
  font-size: 4em;
  padding-inline: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* text-align: right; */
  /* text-align:right ; */
  /* flex-wrap: wrap; */
  /* justify-content:center; */
  /* align-items: flex-end; */
  /* overflow-wrap: break-word; */
  /* justify-content: flex-end; */
  /* align-items: flex-end; */

  /* border: 2px solid red; */
}
.result{
  font-size: 40px;
  /* border: 2px solid red; */
  width: 100%;
  text-align: right;
  align-self: flex-end;

}
.digits{
  display: block;
  font-size: 40px;
  /* border: 2px solid red; */
  padding-top: 10px;
  /* overflow-y: scroll; */
  /* border: 2px solid red; */
  height: 75%;
  width: 100%;
  /* display: flex;
  justify-items: flex-end;
  align-items: flex-end; */

  
  /* flex-wrap: wrap-reverse; */
  overflow-wrap: break-word;




  /* background-color: blue; */


}

.btns {
  height: 66vh;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap:10px 20px;
  padding: 20px;

}
.btn {
  border-radius: 50%;
  border: none;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  height: 2.2em;
  /* color: blue; */
}
.btn:nth-of-type(17) {
  grid-column: 1/3;
  border-radius: 100px;
}
